.video-container {
	padding: 5% 0;

	.titulo {
		color: @h2-color;
		text-transform: uppercase;
		font-weight: 300;
		font-size: 3em;
		margin: 0 0 5% 0;
	}

	.video-responsive {
		position: relative;
	    overflow: hidden;
	    padding-top: 56.25%;

	    iframe {
	    	position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    border: 0;
	    }
	}
}