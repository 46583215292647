.container-pagina{
	.container;

	text-align: left;

	.wrapper-pagina{
		.make-row();

		.titulo-pagina{

			color: @color-principal;
			text-align: center;
			text-transform: uppercase;

		}

		.content{

			font-size: 1.2em;

			a{
				color: @color-principal;

				&:hover{
					color: green;
				}
			}

			ol{

				text-align: left;

				li{

					counter-increment: list;
					list-style-type: none;
					position: relative;

					&:before {
						color: @color-principal;
						content: counter(list) ".";
						left:-32px;
						position: absolute;
						text-align: right;
						width: 26px;
					}

				}

			}

			ul{

				text-align: left;
				list-style: none;
				padding: 0;
				margin: 0;

				li{

					padding-left: 1em; 
					text-indent: -.7em;

					&::before {
						content: "• ";
						color: @color-principal;
					}

				}

			}

			h2{
				color: @color-principal;
				text-align: center;
			}

			p{

			}
		}
	}
}