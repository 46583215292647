.promo-container{

	.make-row();

	background: @gris-flojo;
	padding-top: 3%;
	padding-bottom: 10%;

	@media (min-width: @screen-sm-min) {
		margin-bottom: 2%;		
	}

	@media (min-width: @screen-lg-min) {
		padding-top: 0%;
		padding-bottom: 2%;
	}

	.promo-wrapper{

		.make-sm-column(12);

		.promo-titulo{

			.make-xs-column(12);

			.make-sm-column(10);
			.make-sm-column-offset(1);

			h2{
				text-transform: uppercase;
				font-weight: 700;
				text-align: left;
				margin-top: 0.5em;

				@media (min-width: @screen-sm-min) {		
					margin-top: 1em;
				}

				@media (min-width: @screen-lg-min) {		
					font-size: 2.8em;
				}

				@media (min-width: @screen-xl-min) {		
					font-size: 3.5em;
					margin-bottom: 2%;
				}
			}

		}

		.promo-cuerpo{

			.promo-texto{

				.make-xs-column(12);

				.make-sm-column(6);
				.make-sm-column-offset(1);

				.make-md-column(5);
				.make-md-column-offset(1);

				margin-top: 5%;
				text-align: left;		

				@media (min-width: @screen-sm-min) {
					margin-top: 2%;
				}

				@media (min-width: @screen-lg-min) {
					margin-top: 0;
				}

				p{
					font-size: 1.2em;

					@media (min-width: @screen-lg-min) {
						font-size: 1.3em;
					}
				}

			}

			.promo-logos{
				.make-xs-column(12);
				.make-sm-column(3);
				.make-md-column(4);

				margin-top: 5%;

				@media (min-width: @screen-sm-min) {
					margin-top: 7%;
					margin-left: 3%;
				}

				@media (min-width: @screen-md-min) {
					margin-top: 9%;
				}			

				@media (min-width: @screen-lg-min) {
					margin-top: 3%;
					margin-left: 7%;
				}

				@media (min-width: @screen-xl-min) {
					margin-left: 11%;
				}			

				a{
					color: @color-principal;
					display: inline-block;
					font-size: 1.2em;
					font-family: bariol_bold;
					vertical-align: bottom;
					
					margin-right: 10%;

					@media (min-width: @screen-sm-min) {
						margin-bottom: 10%;
					}

					@media (min-width: @screen-md-min) {
						margin-bottom: 0;
					}

					@media (min-width: @screen-xl-min) {
						margin-right: 7%;
						font-size: 1.5em;
					}

					&:active{

					}
				}

				img{
					.img-responsive;
					display: inline-block;
				}
			}
		}
	}
}