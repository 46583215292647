// ClickEnNet 1.0.0
// Variables
// --------------------------------------------------

//== Colors
//
//## Colores principales del Tema


/* Paleta principal del tema */
@color-principal:            #a1cd90;
@color-principal-fuerte:     #508E39;
@color-principal-flojo:      #C7E3BC;   


/* Paleta de grises */
@gris-principal:       #575757;
@gris-medio:           #191919;
@gris-flojo:           #f2f2f2;

@blanco:               #FFF;
@negro:                #000;


//## Colores para los botones
//
@btn-primario:         #2C3E50;
@btn-aceptado:         #18BC9C;
@btn-informacion:      #3498DB;
@btn-alerta:           #F39C12;
@btn-peligro:          #E74C3C;


//** Color del texto global en el `<body>`.
@text-color:            #565656;

//** Colores de los encabezados '<h*>'
@h1-color:           @color-principal;
@h2-color:           @color-principal;
@h3-color:           @color-principal;
@h4-color:           @color-principal;
@h5-color:           @color-principal;
@h6-color:           @color-principal;

//== Tipografía
//
//## Font, line-height, and color for body text, headings, y más.
//
/* Fuentes Principales del Tema */

@font-face {
	font-family: bariol_regular;
	src: url(../fonts/Bariol-Regular.otf);
}

@font-face {
	font-family: bariol_bold;
	src: url(../fonts/Bariol-Bold.otf);
}

@font-face {
	font-family: bariol_light;
	src: url(../fonts/Bariol-Light.otf);
}

@font-face {
	font-family: bariol_thin;
	src: url(../fonts/Bariol-Thin.otf);
}


@font-family-sans-serif:  bariol_regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-light:       bariol_light, "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-bold:        bariol_bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-thin:        bariol_thin, "Helvetica Neue", Helvetica, Arial, sans-serif;

@font-family-base:        @font-family-sans-serif;

@font-size-base:          1.2em;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.85)); // ~12px

@font-size-h1:            floor((@font-size-base * 2.6)); // ~36px
@font-size-h2:            floor((@font-size-base * 2.15)); // ~30px
@font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px


// // xLarge screen / wide desktop
@screen-xl:                  1600px;
@screen-xl-min:              @screen-xl;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-xl-desktop:          @screen-xl-min;

// So media queries don't overlap when required, provide a maximum
@screen-lg-max:              (@screen-xl-min - 1);

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.
// xLarge screen / wide desktop
@container-xlarge-desktop:      (1240px + @grid-gutter-width);
//** For `@screen-xl-min` and up.
@container-xl:                 @container-xlarge-desktop;

