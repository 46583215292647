.ayuda-wrapper{

	.make-row();

	@media (min-width: @screen-sm-min) {
		padding-top: 1%;
		padding-bottom: 1%;
	}

	.ayuda-container{

		.container;

		margin-bottom: 2%;

		@media (min-width: @screen-xl-min) {
			width: 1470px;
		}

		.ayuda-titulo{
			
			text-align: center;

			margin-top: 10%;
			margin-bottom: 12%;

			@media (min-width: @screen-sm-min) {
				margin-top: 5%;
				margin-bottom: 2.5%;
			}

			h2{
				color: @h2-color;
				text-transform: uppercase;
				font-weight: 300;
				font-size: 3em;

				@media (min-width: @screen-sm-min) {
					font-size: 3.4em;
					margin-top: 2.5%;
					margin-bottom: 0%;
				}

				@media (min-width: @screen-lg-min) {
					margin-top: 2%;
					margin-bottom: 4.5%;
				}
			}
		}

		.ayuda-body{			
			.make-md-column(12);

			.ayuda-section{
				.make-xs-column(12);
				.make-sm-column(12);
				.make-md-column(4);
				.make-lg-column(4);
				
				padding-left: 0;
				padding-right: 0;
				max-width: 315px;			

				@media (min-width: 480px) and (max-width: 767px) {
					max-width: 200px;				
				}

				@media (min-width: @screen-sm-min) {
					margin-bottom: 5%;
					padding-left: 15px;
					padding-right: 15px;
					max-width: 100%;					
				}

				@media (min-width: @screen-md-min) {
					margin-bottom: 0;
				}

				@media (min-width: @screen-lg-min) {
					margin-bottom: 0;
					margin-right: 2%;
					margin-left: 2%;
					max-width: 320px;
				}

				@media (min-width: @screen-xl-min) {
					max-width: 325px;
					margin-bottom: 0;
					margin-right: 4%;
					margin-left: 4%;
					padding: 0;		
				}

				.image-box{

					width: 60%;
					margin-left: auto;
					margin-right: auto;

					@media (min-width: @screen-sm-min) {
						margin-right: 2%;
						width: 28%;
						float: left;
						padding-top: 5%;

						-o-shape-outside: circle();
						-moz-shape-outside: circle();
						-webkit-shape-outside: circle();
						shape-outside: circle();
					}

					@media (min-width: @screen-md-min) {
						float: none;
						width: 75%;
						margin: 0 auto;
					}

					@media (min-width: @screen-lg-min) {
						width: 70%;
					}

					&:after {
						content: "";
						display: block;
						width: 100%;
						height:0;
						padding-bottom: 100%;
						background: @color-principal;
						-moz-border-radius: 50%; 
						-webkit-border-radius: 50%; 
						border-radius: 50%;
						margin-bottom: 3%;
					}

					.image-content{

						float: left;
						width: 100%;					
						line-height: 1em;
						margin-top: -0.5em;
						text-align: center;
						color: white;

						padding-top: 20%;

						@media (min-width: @screen-md-min) {
							padding-top:24%;
						}

						@media (min-width: @screen-lg-min) {
							padding-top: 22%;
						}

						&:hover{
							.rotate-center;
						}

						img{
							.img-responsive;
							margin: 0 auto;
						}
					}				
				}

				.titulo{

					text-align: center;
					font-size: 2em;

					margin-top: 10%;
					margin-bottom: 5%;

					@media (min-width: @screen-sm-min) {
						margin-top: 5%;
						margin-bottom: 2.5%;

						width: 65%;
						float: left;
					}

					@media (min-width: @screen-md-min) {
						margin-bottom: 10%;
						width: 100%;
					}

					h3{
						font-family: @font-family-bold;
						font-size: 0.9em;

						@media (min-width: @screen-sm-min) {
							font-size: 1.1em;
						}

						@media (min-width: @screen-md-min) {
							font-size: 1em;
						}

						@media (min-width: @screen-md-min) {
							font-size: 0.8em;
						}

						@media (min-width: @screen-xl-min) {
							font-size: 1.2em;
						}
						
					}
				}

				.texto{

					padding-left: 0;
					padding-right: 0;
					margin-bottom: 15%;
					font-size: 1.2em;

					@media (min-width: @screen-sm-min) {
						padding-left: 15px;
						padding-right: 15px;
						margin-bottom: 0;

						text-align: justify;
						width: 100%;
					}

					@media (min-width: @screen-md-min) {
						text-align: center;
					}

					@media (min-width: @screen-sm-min) {
						font-size: 1em;
					}

					@media (min-width: @screen-lg-min) {
						padding: 0;
					}

					p{

						@media (min-width: @screen-sm-min) {
							font-size: 1.15em;
						}

						@media (min-width: @screen-md-min) {
							font-size: 1.2em;
						}
					}
				}
			}
		}
	}
}