footer{

	.container-fluid;
	
	background-color: @gris-principal;
	border-top: 10px solid @color-principal;
	color: @blanco;
	text-align: center;

	padding-top: 10%;
	padding-bottom: 10%;

	@media (min-width: @screen-sm-min) {
		text-align: left;
		padding-top: 2%;
		padding-bottom: 2%;
		margin-top: 5%;
	}

	@media (min-width: @screen-lg-min) {
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 5%;
	}

	.menu-container{
		.footer-menu{

			.footer-link{
				.make-xs-column(12);
				.make-sm-column(6);
				.make-md-column(3);
				margin-bottom: 3%;
			}
			

			text-align: center;
			font-size: 1.2em;
			margin-top: 5%;
			a{
				color:white;
			}

			@media (min-width: @screen-sm-min) {
				margin-top: 5%;
				margin-bottom: 3%;
			}

			@media (min-width: @screen-md-min) {
				font-size: 1.4em;
				line-height: 2em;
			}

			@media (min-width: @screen-lg-min) {
				line-height: 1em;
			}

		}
	}

	.footer-container{

		.container;

		margin-bottom: 3%;

		@media (min-width: @screen-sm-min) {
			margin-top: 3%;
			margin-bottom: 0;
		}

		@media (min-width: @screen-md-min) {
			margin-bottom: 2%;
		}

		@media (min-width: @screen-lg-min) {
			position: relative;
		}

		.footer-info-section{

			.make-xs-column(12);
			.make-sm-column(5);
			.make-md-column(6);

			text-align: left;
			font-size: 1.2em;
			
			a{
				color:white;
			}

			@media (min-width: @screen-md-min) {
				font-size: 1.4em;
				line-height: 2em;
			}

			@media (min-width: @screen-lg-min) {
				line-height: 1em;
			}

			@media (min-width: @screen-xl-min) {
				width: 35%;
			}	
		}

		.footer-social-section{

			.make-xs-column(12);
			.make-sm-column(3);
			.make-md-column(2);

			margin-bottom: 10%;
			margin-top: 10%;

			@media (min-width: @screen-sm-min) {
				margin-top: 0;
				margin-bottom: 0;
				width: 16%;
			}

			@media (min-width: @screen-lg-min) {
				position: absolute;
				bottom: 5px;
				left: 50%;
			}

			@media (min-width: @screen-xl-min) {
				bottom: 10px;
				left: 45%;
			}

			a{
				border-radius: 50%;

				&:hover{
					.heartbeat;
				}

				.fa-stack{
					color: @blanco;

					.fa-facebook {
						color: @gris-principal;
					}

					.fa-youtube{
						color: @gris-principal;
					}
				}
			}
		}

		.footer-links-section{

			.make-xs-column(12);
			.make-sm-column(4);
			.make-md-column(3);
			.make-lg-column(5);

			margin-bottom: 5%;
			line-height: 2.5em;
			
			@media (min-width: @screen-sm-min) {
				line-height: 2.6em;
			}

			@media (min-width: @screen-md-min) {
				line-height: 3em;
			}

			@media (min-width: @screen-lg-min) {
				line-height: 1em;
				margin-bottom: 0;			
				position: absolute;
				bottom: 5px;
				right: 0;
			}

			@media (min-width: @screen-xl-min) {
				bottom: 10px;
				width: 40.333333%;
			}

			.footer-link{
				text-align: left;
				font-size: 1.2em;
				text-align: center;

				@media (min-width: @screen-lg-min) {
					margin-left: 5%;
					text-align: left;
				}
				

				a{
					color: @blanco;

					&:hover{
						color: @color-principal;
					}
				}
				
				@media (min-width: @screen-lg-min) {
					display: inline-block;
				}
			}
		}
	}
}