.post-wrapper{

	.make-row();

	.post{
		.make-xs-column(12);
		.make-sm-column(12);
		.make-md-column(12);
		.make-lg-column(12);

		padding-top: 7%;

		@media (min-width: @screen-sm-min) {
			padding-top: 5%;
		}

		@media (min-width: @screen-md-min) {
			padding-top: 3%;
		}

		@media (min-width: @screen-lg-min) {
			margin-bottom: 2%;
		}

		.link-atras{
			color: @color-principal;
			font-size: 1.2em;
		}

		h1{
			color: @color-principal;
			margin-top: 0.5em;

			@media (min-width: @screen-sm-min) {
				margin-top: 1em;			
			}

			@media (min-width: @screen-md-min) {
				margin-bottom: 5%;
			}

			@media (min-width: @screen-lg-min) {
				margin-bottom: 5%;
			}
		}

		.post-image{
			.make-sm-column(8);
			.make-sm-column-offset(2);

			margin-top: 5%;

			@media (min-width: @screen-sm-min) {
				margin-top: 0;
				margin-bottom: 2%;
			}

			@media (min-width: @screen-lg-min) {
				margin-bottom: 5%;
			}

			img{
				.img-responsive;
				margin: 0 auto;
			}
		}

		.post-date{
			.make-xs-column(12);
			.make-sm-column(12);
			.make-md-column(12);
			.make-lg-column(12);

			font-size: 1.4em;

			@media (min-width: @screen-sm-min) {
				margin-bottom: 2%;
			}

			@media (min-width: @screen-lg-min) {
				margin-bottom: 3%;
			}
		}

		.post-text{
			.make-sm-column(8);
			.make-sm-column-offset(2);

			text-align: left;
			font-size: 1.2em;
			margin-top: 5%;

			@media (min-width: @screen-sm-min) {
				margin-top: 0;
			}

			@media (min-width: @screen-lg-min) {
				margin-bottom: 3%;
			}
		}

		.post-footer{
			.make-xs-column(12);
			.make-sm-column(12);
			.make-md-column(12);
			.make-lg-column(12);

			margin-top: 5%;
			margin-bottom: 5%;

			@media (min-width: @screen-sm-min) {
				margin-top: 0;
				margin-bottom: 0;
			}

			p{

				@media (min-width: @screen-sm-min) {
					margin-top: 2%;
				}

				a{
					font-size: 1.2em;

					@media (min-width: @screen-sm-min) {
						margin-left: 3%;
						margin-right: 3%;
					}					
				}
			}
		}		
	}
}