.blog-container{
	.make-row();

	.blog-wrapper{
		.make-xs-column(12);
		.make-sm-column(12);
		.make-md-column(12);
		.make-lg-column(12);

		.blog-titulo{

			h1{
				color: @h1-color;
				text-transform: uppercase;
				font-weight: 300;
				margin-top: 10%;				

				@media (min-width: @screen-sm-min) {
					font-size: 4em;
					margin-top: 5%;
				}

				@media (min-width: @screen-lg-min) {
					margin-top: 2%;
					margin-bottom: 5%;
				}
			}
		}

		.post{
			.make-xs-column(12);
			.make-sm-column(12);
			.make-md-column(12);
			.make-lg-column(10);
			.make-lg-column-offset(1);

			margin-top: 5%;
			margin-bottom: 5%;

			@media (min-width: @screen-sm-min) {
				margin-top: 2%;
				margin-bottom: 2%;
			}

			@media (min-width: @screen-lg-min) {
				margin-top: 0;
				margin-bottom: 0;
			}

			.post-image{

				.make-sm-column(5);

				img{
					.img-responsive;
				}
			}

			.post-body{
				.make-xs-column(12);
				.make-sm-column(7);

				text-align: left;

				.post-title{
					.make-xs-column(12);
					.make-sm-column(12);
					.make-md-column(12);
					.make-lg-column(12);

					display: inline-block;
					text-align: left;
					padding-left: 0;
					padding-right: 0;

					@media (min-width: @screen-sm-min) {
						padding-left: 15px;
						padding-right: 15px;
					}

					h3{
						display: inline-block;
						font-size: 2em;

						@media (min-width: @screen-sm-min) {
							margin-top: 0;
							margin-bottom: 1%;
						}

						@media (min-width: @screen-lg-min) {
							font-size: 2.5em;
							margin-top: 0;
							margin-bottom: 0;
						}

						@media (min-width: @screen-lg-min) {
							font-size: 1.2em;
							margin-top: 0;
							margin-bottom: 0;
						}

						a{
							color: @color-principal;
							margin-right: 1%;

							@media (min-width: @screen-xl-min) {
								font-size: 3em;
								margin-top: 0;
							}
						}							
					}

					.date{
						display: inline-block;
						.make-sm-column(5);

						@media (min-width: @screen-sm-min) {
							float: none;
						}

						@media (min-width: @screen-lg-min) {
							float: none;
						}

						@media (min-width: @screen-xl-min) {
							font-size: 1.5em;
						}
					}
				}

				.post-text{
					.make-xs-column(12);
					.make-sm-column(12);
					.make-md-column(12);
					.make-lg-column(12);

					text-align: left;

					padding-left: 0;
					padding-right: 0;

					@media (min-width: @screen-sm-min) {
						padding-left: 15px;
						padding-right: 15px;
					}
				}

				.post-footer{

					text-align: left;

					.make-xs-column(12);
					.make-sm-column(12);
					.make-md-column(12);
					.make-lg-column(12);

					a{
						color: @color-principal;
						font-weight: 700;

						@media (min-width: @screen-lg-min) {
							font-size: 1.5em;
						}

						@media (min-width: @screen-xl-min) {
							font-size: 1.2em;
						}
					}
				}
			}

			.post-border-bottom{
				.make-xs-column(12);
				.make-sm-column(12);
				.make-md-column(12);
				.make-lg-column(12);

				margin-top: 10%;
				margin-bottom: 10%;
				border-top: 3px solid @color-principal;

				@media (min-width: @screen-sm-min) {
					margin-top: 5%;
					margin-bottom: 5%;
				}				

				@media (min-width: @screen-lg-min) {
					margin-top: 5%;
					margin-bottom: 5%;
				}
			}
		}		
	}
}