/**
 * ----------------------------------------
 * Clases
 * ----------------------------------------
 */

 .rotate-center {
 	-webkit-animation: rotate-center 1s ease-in-out both;
 	animation: rotate-center 1s ease-in-out both;
 }

 .slide-top {
 	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 	animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 }

 .heartbeat {
 	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
 	animation: heartbeat 1.5s ease-in-out infinite both;
 }

 .scale-up-center {
 	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
 	animation: scale-up-center 0.4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
 }


/**
 * ----------------------------------------
 * Keyframes
 * ----------------------------------------
 */


 // animation rotate-center

 @-webkit-keyframes rotate-center {
 	0% {
 		-webkit-transform: rotate(0);
 		transform: rotate(0);
 	}
 	100% {
 		-webkit-transform: rotate(360deg);
 		transform: rotate(360deg);
 	}
 }
 @keyframes rotate-center {
 	0% {
 		-webkit-transform: rotate(0);
 		transform: rotate(0);
 	}
 	100% {
 		-webkit-transform: rotate(360deg);
 		transform: rotate(360deg);
 	}
 }


  // animation slide-top

  @-webkit-keyframes slide-top {
  	0% {
  		-webkit-transform: translateY(0);
  		transform: translateY(0);
  	}
  	100% {
  		-webkit-transform: translateY(-20px);
  		transform: translateY(-20px);
  	}
  }
  @keyframes slide-top {
  	0% {
  		-webkit-transform: translateY(0);
  		transform: translateY(0);
  	}
  	100% {
  		-webkit-transform: translateY(-20px);
  		transform: translateY(-20px);
  	}
  }


 // animation heartbeat
 
 @-webkit-keyframes heartbeat {
 	from {
 		-webkit-transform: scale(1);
 		transform: scale(1);
 		-webkit-transform-origin: center center;
 		transform-origin: center center;
 		-webkit-animation-timing-function: ease-out;
 		animation-timing-function: ease-out;
 	}
 	10% {
 		-webkit-transform: scale(0.91);
 		transform: scale(0.91);
 		-webkit-animation-timing-function: ease-in;
 		animation-timing-function: ease-in;
 	}
 	17% {
 		-webkit-transform: scale(0.98);
 		transform: scale(0.98);
 		-webkit-animation-timing-function: ease-out;
 		animation-timing-function: ease-out;
 	}
 	33% {
 		-webkit-transform: scale(0.87);
 		transform: scale(0.87);
 		-webkit-animation-timing-function: ease-in;
 		animation-timing-function: ease-in;
 	}
 	45% {
 		-webkit-transform: scale(1);
 		transform: scale(1);
 		-webkit-animation-timing-function: ease-out;
 		animation-timing-function: ease-out;
 	}
 }
 @keyframes heartbeat {
 	from {
 		-webkit-transform: scale(1);
 		transform: scale(1);
 		-webkit-transform-origin: center center;
 		transform-origin: center center;
 		-webkit-animation-timing-function: ease-out;
 		animation-timing-function: ease-out;
 	}
 	10% {
 		-webkit-transform: scale(0.91);
 		transform: scale(0.91);
 		-webkit-animation-timing-function: ease-in;
 		animation-timing-function: ease-in;
 	}
 	17% {
 		-webkit-transform: scale(0.98);
 		transform: scale(0.98);
 		-webkit-animation-timing-function: ease-out;
 		animation-timing-function: ease-out;
 	}
 	33% {
 		-webkit-transform: scale(0.87);
 		transform: scale(0.87);
 		-webkit-animation-timing-function: ease-in;
 		animation-timing-function: ease-in;
 	}
 	45% {
 		-webkit-transform: scale(1);
 		transform: scale(1);
 		-webkit-animation-timing-function: ease-out;
 		animation-timing-function: ease-out;
 	}
 }

 // animation scale-up-center

 @-webkit-keyframes scale-up-center {
 	0% {
 		-webkit-transform: scale(0.5);
 		transform: scale(0.5);
 	}
 	100% {
 		-webkit-transform: scale(1);
 		transform: scale(1);
 	}
 }
 @keyframes scale-up-center {
 	0% {
 		-webkit-transform: scale(0.5);
 		transform: scale(0.5);
 	}
 	100% {
 		-webkit-transform: scale(1);
 		transform: scale(1);
 	}
 }