.navbar{

	background-color: transparent;
	border:none;

	@media (min-width: @screen-sm-min) {
		height: 225px !important;
	}

	@media (min-width: @screen-md-min) {
		height: 260px !important;
	}

	@media (min-width: @screen-lg-min) {
		height: 195px !important;
	}

	@media (min-width: @screen-xl-min) {
		height: 225px !important;
	}

	&.navbar-default{

		@media (min-width: @screen-md-min) {
			background-color: transparent;
			border:none;
		}
		
		// Zona de los links alineado a la izquierda
		.navbar-nav{

			li{
				a{
					color: @gris-principal;
					background-color: transparent;
					font-family: @font-family-light;

					@media (min-width: @screen-md-min) {
						padding-top: 15px;
						padding-bottom: 15px;
					}

					&.active{
						background-color: transparent;
						color: @color-principal;
						font-weight: 700;
						background-color: transparent;
					}

					&:hover{
						background-color: transparent;
						color: @color-principal;
						font-family: @font-family-light;
					}

					&:focus{
						background-color: transparent;
						color: @color-principal;
						font-family: @font-family-light;
					}

				}
			}
		}

		.navbar-subheader{

			background-color: @color-principal;
			height: 15px;

			@media (min-width: @screen-sm-min) {
				height: 35px;
				padding-right: 5%;
				padding-top: 3px;
			}

			p{
				
				display: none;
				font-family: @font-family-bold;
				font-weight: 700;
				color: @gris-principal;

				@media only screen and (min-width : 768px) {
					display: block;
					float: right;
					font-size: 1em;
				}
				
				@media (min-width: @screen-md-min) {
					display: block;
					float: right;
					font-size: 1em; 				
				}

				@media (min-width: @screen-xl-min) {
					font-size: 1.1em;
				}

				a{
					font-family: @font-family-light;
					color: #575757;
					text-transform: uppercase;
					margin-left: 1px;
					
					&:first-child{
						float: right;

						&::before {
							content: " |"; 
							color: #575757;
							margin-left: 4px;
						}
					}

					&.active{
						color: @blanco;
					}

					&:hover{
						color: @blanco;
					}
				}
			}

		}

		.navbar-header{

			.navbar-brand{

				height: auto;
				width: 80%;

				@media (min-width: @screen-md-min) {
					padding: 10px;
					width: 100%;
				}

				@media (min-width: @screen-lg-min) {
					height: 140px;
				}

				@media (min-width: @screen-xl-min) {
					padding: 10px;
				}

				.logo{
					.img-responsive;

					@media (min-width: @screen-md-min) {
						margin-left: 12%;
						margin-top: 3%;
					}
				}
			}
		}

		.navbar-right{

			font-weight: 600;
			font-size: 1.2em;

			@media (min-width: @screen-sm-min) {
				margin-right: 20px;
				font-size: 1.1em;
			}

			@media (min-width: @screen-md-min) {
				height: 50px;
				margin-top: 4%;
				bottom: 0;
				position: absolute;
				right: 12%;
				font-size: 1.2em;
			}

			@media (min-width: @screen-lg-min) {
				right: 3%;
			}

			@media (min-width: @screen-xl-min) {
				font-size: 1.5em;
				font-weight: 600;
			}

			.mobile-language-selector{
				.make-xs-column(12);
				display: inline-block;

				a{
					.make-xs-column(6);
					text-align: center;
					display: inline-block;
				}

				@media (min-width: @screen-sm-min) {
					display: none;
				}
			}

		}

		&.navbar-collapse{
			border-color: none;
			border-color: @color-principal;
		}

		.navbar-toggle{
			background-color: @blanco;
			border-color: @color-principal;

			.icon-bar{
				background-color: @color-principal;
			}

			&:hover{
				background-color: @color-principal;
				border-color: @color-principal;

				.icon-bar{
					background-color: @blanco;
				}
			}
		}
	}	
}