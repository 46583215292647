.latest-posts-wrapper{
	.make-row();

	.latest-posts-container{

		.container;

		@media (min-width: @screen-xl-min) {
			width: 1600px;
		}

		.latest-posts-titulo{

			margin-bottom: 10%;

			@media (min-width: @screen-sm-min) {
				margin-top: 7%;
				margin-bottom: 7%;
			}

			@media (min-width: @screen-md-min) {
				margin-top: 2%;
				margin-bottom: 5%;
			}

			h2{
				color: @h2-color;
				text-transform: uppercase;
				font-weight: 300;
				font-size: 3em;
				
				@media (min-width: @screen-sm-min) {
					font-size: 4em;
				}

				@media (min-width: @screen-xl-min) {
					font-size: 5em;
				}
			}
		}

		.latest-posts-section{
			.make-xs-column(12);
			.make-sm-column(12);
			.make-md-column(12);

			@media (min-width: @screen-xl-min) {
				width: 80%;
				margin-left: 10%;
			}	

			.latest-post{

				.make-xs-column(12);

				padding-left: 0;
				padding-right: 0;
				margin-bottom: 10%;		

				@media (min-width: @screen-md-min) {
					margin-top: 2%;
					margin-bottom: 5%;
				}

				@media (min-width: @screen-lg-min) {
					min-height: 200px;
					padding: 10px;
				}	

				.latest-post-image{
					.make-xs-column(12);
					.make-sm-column(5);	

					@media (min-width: @screen-sm-min) {
						margin-top: 1%;
					}

					@media (min-width: @screen-md-min) {
						margin-top: 0;
					}

					img{
						.img-responsive;
					}
				}

				.latest-post-body{

					.make-xs-column(12);
					.make-sm-column(7);

					text-align: left;
					padding-left: 0;
					padding-right: 0;

					@media (min-width: @screen-sm-min) {
						padding-left: 15px;
						padding-right: 15px;
					}

					.latest-post-title{

						.make-xs-column(12);

						display: inline-block;

						h3{
							color: @h3-color;
							display: inline-block;
							margin-right: 10%;
							font-size: 2em;

							@media (min-width: @screen-sm-min) {
								font-size: 2em;
								margin-top: 0;
								margin-right: 5%;							
							}

							@media (min-width: @screen-md-min) {
								font-size: 2.5em;
								margin-right: 0%;
								margin-bottom: 3%;
							}

							@media (min-width: @screen-lg-min) {
								font-size: 3em;
								margin-top: 0;
								margin-bottom: 0;
							}						
							
							@media (min-width: @screen-xl-min) {
								font-size: 3em;
								margin-top: 0;
								margin-bottom: 0;
							}						   
						}

						.date{
							display: inline-block;

							@media (min-width: @screen-sm-min) {
								font-size: 1em;
								margin-left: 5%;
							}

							@media (min-width: @screen-md-min) {
								font-size: 1.2em;
							}

							@media (min-width: @screen-xl-min) {
								font-size: 1.2em;
							}
						}
					}

					.latest-post-text{
						.make-xs-column(12);

						font-family: bariol_regular !important;

						@media (min-width: @screen-sm-min) {
							margin-bottom: 3%;
							display: table;
						}

						p{	

							font-family: bariol_regular !important;

							span{
								text-align: justify !important;
								font-size: 1.2em !important;
								font-family: bariol_regular !important;
								color: @text-color !important;
							}						
						}
					}

					.latest-post-footer{

						.make-xs-column(12);

						text-align: left;

						a{
							color: @color-principal;
							font-weight: 700;
							font-size: 1.2em;
						}
					}
				}			
			}			
		}

		.latest-posts-bottom{
			
			.make-xs-column(12);
			.make-sm-column(12);

			.bottom{
				.make-sm-column(5);

				border-bottom: 2px solid @color-principal;
				
				@media (min-width: @screen-sm-min) {
					margin-bottom: 0;
					margin-top: 0;
					width: 37%;
					margin-top: 5%;
				}

				@media (min-width: @screen-md-min) {
					width: 40%;
				}			
			}

			.blog-btn{
				.make-xs-column(12);
				.make-sm-column(7);

				.latest-post-text-link{

					color: @color-principal;					
					font-size: 1.5em;
					display: inline-block;
					margin-top: 5%;
					margin-bottom: 5%;

					@media (min-width: @screen-sm-min) {
						font-size: 1.5em;
						margin-top: 5%;
						margin-right: 4%;
						margin-bottom: 0;
						vertical-align: top;
					}

					@media (min-width: @screen-md-min) {
						font-size: 2em;
						margin-top: 5%;
						margin-left: 5%;
						margin-right: 0;
						float: left;
					}

					@media (min-width: @screen-lg-min) {
						margin-left: 5%;
						margin-top: 4%;
						margin-left: 5%;
						float: left;
					}

					span{

						@media (min-width: @screen-lg-min) {
							vertical-align: sub;
						}
					}					
				}

				.latest-post-image-link{
					display: inline-block;

					@media (min-width: @screen-sm-min) {
						width: 65px;
					}

					@media (min-width: @screen-md-min) {
						width: 80px;
					}

					@media (min-width: @screen-lg-min) {
						margin-top: 1%;
						margin-left: 2%;
						width: 80px;
						float: left;
					}

					img{
						.img-responsive;
						margin: 0 auto;
						
						
						&:hover{
							.heartbeat;
						}
					}
				}
			}		
		}
	}
}