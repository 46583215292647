// CAROUSEL
// --------

.carousel {

  @media (min-width: @screen-lg-min) {
    margin-top: 2.5%;
    margin-bottom: 3%;
  }

}

.carousel-inner {
  overflow: hidden;
  width: 100%;
  position: relative;

  .item{

    img{

      .img-responsive;

      height: 160px;
      width: 100%;
      object-fit: cover;

      @media (min-width: @screen-sm-min) {
        height: 290px;
      }

      @media (min-width: @screen-md-min) {
        height: 320px;
      }

      @media (min-width: @screen-lg-min) {
        height: 395px;
      }

      @media (min-width: @screen-xl-min) {
        height: 550px;
      }
    }

    .carousel-caption{
      display: none;
    }
  }
}

.carousel-indicators{

  @media (min-width: @screen-lg-min) {

    right: 5%;
    width: 50%;
    margin-left: 0;
    padding-left: 0;
    text-align: right;

  }

  li{

    &.active{
      width: 15px;
      height: 15px;
    }

    @media (min-width: @screen-lg-min) {
      width: 15px;
      height: 15px;
    }

  }

}

.carousel-control{

  &.left{

    background-image: none;
    background-repeat: none;

    .glyphicon-chevron-left{

      background-color: @gris-flojo;
      border-radius: 50%;
      width: 30px;
      height: 30px;

      @media (min-width: @screen-lg-min) {
        padding-top: 6%;
        padding-left: 5%;
        margin-left: 10px;
        margin-top: -25px;
        width: 50px;
        height: 50px;
      }

      @media (min-width: @screen-xl-min) {
        padding-top: 4%;
        padding-left: 3%;
      }

      &:before {
        content: " ";
        display:block;
        background:url('https://s3-eu-west-1.amazonaws.com/margarigo/arrow-icon-left.png') no-repeat;
        
        width: 27px;
        height: 30px;
        background-size: 55%;
        background-position: center;

        @media (min-width: @screen-lg-min) {
          width:30px;
          height:30px;
        }

        @media (min-width: @screen-xl-min) {
          background-size: 85%;
        }
      }
    }
  }

  &.right{

    background-image: none;
    background-repeat: none;

    .glyphicon-chevron-right{

      background-color: @gris-flojo;
      border-radius: 50%;
      width: 30px;
      height: 30px;

      @media (min-width: @screen-lg-min) {
        padding-top: 6%;
        padding-left: 5%;
        margin-left: 10px;
        margin-top: -25px;
        width: 50px;
        height: 50px;
      }

      @media (min-width: @screen-xl-min) {
        padding-top: 4%;
        padding-left: 3%;
      }

      &:before {
        content: " ";
        display:block;
        background:url('https://s3-eu-west-1.amazonaws.com/margarigo/arrow-icon-right.png') no-repeat;

        width: 27px;
        height: 30px;
        background-size: 55%;
        background-position: center;

        @media (min-width: @screen-lg-min) {
          width:30px;
          height:30px;
        }

        @media (min-width: @screen-xl-min) {
          background-size: 85%;
        }
      }
    }
  }
}