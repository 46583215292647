// PAGINATION
// ----------

.pagination {
	.make-xs-column(12);
	.make-sm-column(12);
	.make-md-column(12);
	.make-lg-column(12);

	display: flex;
	justify-content: center;
	margin-bottom: 10%;

	@media (min-width: @screen-sm-min) {
		margin-bottom: 5%;
	}

	ul{
		display: flex;
		justify-content: center;
	}

	li {
		margin-left: 0;
		margin-right: 5%;

		@media (min-width: @screen-sm-min) {
			margin-left: 1%;
			margin-right: 0;
		}

		@media (min-width: @screen-lg-min) {
			margin-left: 1%;
			margin-right: 0;
		}

		a{
			border-radius: 50%;
			border: 3px solid @color-principal;
			background-color: @color-principal-flojo;
			color: @blanco;

			&:hover{
				background-color: @color-principal;
				border: 3px solid @color-principal-flojo;
				cursor: pointer;
				color: @blanco;
			}
		}


		span{
			margin-left: 5%;
			margin-right: 5%;
			border-radius: 50%;
			border: 3px solid @color-principal;
			background-color: @color-principal-flojo;
		}


		&.active{
			a{
				border-radius: 50%;
				border: 3px solid @color-principal;
				background-color: @color-principal-flojo;

				&:hover{
					background-color: @color-principal;
					border: 3px solid @color-principal;
				}
			}


			span{
				margin-left: 5%;
				margin-right: 5%;
				border-radius: 50%;
				border: 3px solid @color-principal;
				background-color: @color-principal-flojo;

				&:hover{
					background-color: @color-principal;
					border: 3px solid @color-principal;
				}
			}
		}

		&.disabled{

			span{
				border: 3px solid @color-principal-flojo;
				background-color: @gris-flojo;

				&:hover{
					background-color: @gris-principal;
					border: 3px solid @color-principal;
				}
			}
		}

		&:first-child{
			a{
				span{
					background-color: @color-principal-flojo;
					border-color: @color-principal;
					border: 3px solid @color-principal;

					&:hover{
						background-color: @color-principal;
						border-color: @color-principal-flojo;
						cursor: pointer;
					}
				}
			}
		}

		&:last-child{
			a{
				span{
					background-color: @color-principal-flojo;
					border-color: @color-principal;
					border: 3px solid @color-principal;
					color: @blanco;

					&:hover{
						background-color: @color-principal;
						border-color: @color-principal-flojo;
						cursor: pointer;
						color: @blanco;
					}
				}
			}
		}
	}

}

// Centered
.pagination-centered {
	text-align: center;
}
.pagination-right {
	text-align: right;
}