.para-quien-wrapper-titulo{
	.make-row();

	@media (min-width: @screen-sm-min) {
		padding-top: 0%;
		padding-bottom: 2.5%;	
	}

	@media (min-width: @screen-md-min) {
		margin-bottom: 0;
		padding-top: 2%;
		padding-bottom: 2%;
	}

	.para-quien-titulo{

		.make-sm-column(12);

		text-align: center;
		font-size: 3em;

		@media (min-width: @screen-sm-min) {
			margin-bottom: 0%;
			font-size: 1.2em;
		}

		@media (min-width: @screen-md-min) {
			margin-bottom: 2%;
			margin-top: 0;
			margin-bottom: 0;
		}

		h2{
			color: @h2-color;
			text-transform: uppercase;
			font-weight: 300;
			font-size: 1em;

			@media (min-width: @screen-sm-min) {
				font-size: 3.4em;
				margin-top: 2%;
				margin-bottom: 2%;
			}

			@media (min-width: @screen-lg-min) {
				font-size: 3em;
				margin-top: 0;
				margin-bottom: 0;
			}		
		}		
	}
}

.para-quien-container{
	.make-row();

	background-color: @gris-principal;
	padding-top: 10%;
	padding-bottom: 10%;

	@media (min-width: @screen-sm-min) {
		padding-top: 7%;
		padding-bottom: 7%;
	}

	@media (min-width: @screen-md-min) {
		padding-top: 5%;
		padding-bottom: 5%;   
		margin-bottom: 0;
	}

	.para-quien-wrapper{

		.make-sm-column(12);

		.para-quien-section{

			.make-xs-column(12);
			.make-sm-column(6);
			.make-md-column(3);

			font-size: 1.2em;

			@media (min-width: @screen-sm-min) {
				margin-bottom: 5%;
			}

			@media (min-width: @screen-md-min) {
				margin-bottom: 0;
			}

			.para-quien-image-box{

				img{
					.img-responsive;
					margin-left: auto;
					margin-right: auto;

					&:hover{
						.slide-top;
					}
				}
			}

			h3{
				text-align: center;
				color: @blanco;
				font-family: @font-family-bold;
				font-size: 2em;		

				@media (min-width: @screen-sm-min) {
					margin-top: 10%;
					margin-bottom: 5%;
					font-size: 2em;

				}

				@media (min-width: @screen-md-min) {
					font-size: 1.7em;
					margin-bottom: 12%;
					margin-top: 5%;

				}
			}

			p{	
				color: @blanco;				
				margin: 0 0 3em;

				@media (min-width: @screen-md-min) {
					text-align: left;
					padding-left: 25px;
				}
			}
		}
	}	
}