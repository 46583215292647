.sobre-mi-titulo-wrapper{
	.make-row();

	margin-top: 5%;

	@media (min-width: @screen-sm-min) {
		margin-top: 5%;
		margin-bottom: 2.5%;
	}

	@media (min-width: @screen-md-min) {
		margin-top:  1%;
		margin-bottom: 2%;
	}

	.sobre-mi-titulo{

		.make-sm-column(12);

		text-align: center;

		h2{
			color: @h2-color;
			text-transform: uppercase;
			font-weight: 300;
			font-size: 3em;		

			@media (min-width: @screen-sm-min) {
				font-size: 3.4em;
				margin-top: 5%;
				margin-bottom: 1.5%;
			}

			@media (min-width: @screen-lg-min) {
				font-size: 3em;
				margin-top: 2%;
				margin-bottom: 0;
			}		
		}		
	}
}

.sobre-mi-container{

	.make-row();

	background-color: @color-principal;

	padding-top: 5%;
	padding-bottom: 10%;

	@media (min-width: @screen-sm-min) {
		padding-top: 2.5%;
		padding-bottom: 5%;
	}

	@media (min-width: @screen-lg-min) {
		padding-top: 0;
		padding-bottom: 3%;
	}

	.sobre-mi-wrapper{

		.make-md-column(12);		

		.sobre-mi-cuerpo{			
			.make-md-column(12);

			.sobre-mi-imagen{

				.make-xs-column(12);
				.make-sm-column(12);
				.make-md-column(5);
				.make-lg-column(4);

				margin-top: 5%;
				margin-bottom: 5%;

				@media (min-width: @screen-sm-min) {
					margin-bottom: 0;
				}

				@media (min-width: @screen-md-min) {
					float: right;
				}

				.sobre-mi-borde-imagen{

					border: 1px solid #fff;
					width: 75%;
					padding: 7px;
					display: block;
					margin: 0 auto;

					@media (min-width: @screen-sm-min) {
						width: 30%;
					}

					@media (min-width: @screen-md-min) {
						width: 53%;
						margin-top: 50%;
					}

					@media (min-width: @screen-lg-min) {
						width: 50%;
						margin-top: 38%;
					}

					@media (min-width: @screen-xl-min) {
						width: 34%;
						margin-top: 8%;
					}

					img{
						.img-responsive;
						margin: 0 auto;
					}
				}
			}

			.sobre-mi-texto{

				.make-xs-column(12);

				.make-sm-column(10);
				.make-sm-column-offset(1);

				.make-md-column(6);
				.make-md-column-offset(1);

				text-align: left;
				margin-top: 5%;

				@media (min-width: @screen-sm-min) {
					padding-left: 0;
				}

				p{
					color: #fff;
					font-size: 1.2em;
				}
			}		
		}
	}
}