// ClickEnNet 1.0
// Archivo de estilos basicos del tema
// --------------------------------------------------
// 
// 

/* Estilos basicos */

body {
	line-height: 2em;
	margin: 0 auto;
	font-size: 1.5em;
	font-family: @font-family-base;
}

h1, h2, h3, h4, h5, h6{
	font-family: @font-family-light;
}

/* Estilos botones */

a { 
	color: @color-principal;
	text-decoration: none;
	cursor: pointer;
	background-color: transparent;

	&:hover{
		text-decoration: none;
		color: @color-principal-fuerte;
		background-color: transparent;
	}

	&:active{
		color: @color-principal-flojo;
		text-decoration: none;
		background-color: transparent;
	}

	&:visited{
		color: @color-principal;
		text-decoration: none;
		background-color: transparent;
	}

	&:focus{
		color: @color-principal;
		text-decoration: none;
		background-color: transparent;
		border: none;
	}
}

// Estilos para el boton de scroll arriba

.scrollToTop {
	display: none;
	position: fixed;
	bottom: 20px;
	right: 15px;
	z-index: 99;
	outline: none;
	background-color: @gris-flojo;
	border: 3px solid @color-principal;
	color: white;
	cursor: pointer;
	padding: 6px 14px;
	border-radius: 50%;

	.fa-chevron-up{
		color: @color-principal;
	}

	&:hover {
		background-color: @gris-flojo;
	}

	&:visited {
		background-color: @gris-flojo;
	}
}

// Codigo para el aviso de cookies

.cookies-box {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 99999;
	background-color: @gris-principal;
	color: @blanco;
	font-size: 12px;
	padding: 5px;
	display: flex;
	justify-content: center;
	border-top: 2px solid @color-principal;
	
	@media (min-width: @screen-md-min) {
		font-size: 1.2em;
		padding: 10px;
	}

	a{
		color: @text-color;
		margin-right: 15px;

		i{
			color: @text-color;
		}

		@media (min-width: @screen-md-min) {
			margin-right: 0;
		}
	}

	.cookies-accept {
		background-color: @color-principal;
		padding: 0px 12px;
		margin: 0px 15px;
		color: @blanco;
		border-radius: 50%;
		border-style: none;

		@media (min-width: @screen-sm-min) {
			border-radius: 0;
		}

		@media (min-width: @screen-md-min) {
			padding: 0px 15px;
			margin: 0px 15px;
			color: @blanco;
			border: none;
			border-radius: 0;
		}
	}
}





